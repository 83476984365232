<template>
  <div style="width: 100%; height: 100%" class="leanCss">
    <div class="mian_box">
      <div style="display: flex; width: 100%">
        <div class="wen_box_test">
          <img
            class="wen_img"
            src="../../assets/images/Examine_img/more.png"
            alt=""
          />
          查看全部问题
        </div>
        <div class="ti_box">
          <span style="margin-left: 2%">答题区</span>
          <div class="time">
            剩余时间:<span style="color: red; margin-left: 5px">{{
              branch + ':' + second
            }}</span>
          </div>
        </div>
      </div>
      <div style="display: flex; width: 100%">
        <div class="left_test">
          <div class="t1">
            <span style="margin-left: 15px; font-size: 20px">题目</span>
          </div>
          <div class="t2">
            <div
              class="item_"
              v-for="(item, index) in questionList.length"
              :key="index"
              @click="clickItem(index)"
              :class="{
                item_active: questionList[index].answer != '',
                item_isSkipActive: questionList[index].isSkip == 1,
              }"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </div>
        <div class="right2">
          <div class="qustion">
            <div class="qustion_item">
              {{ itemIndex + 1 }}、 {{ dataItem.question }}
              <el-input
                v-if="dataItem.isSelect == 1"
                class="selectOption-inp"
                v-model="selectaAnswer"
                style="width: 150px"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div v-if="dataItem.isSelect == 0" style="margin-top: 50px">
            <el-button
              class="chooseItem"
              style="background: white; color: black; border-color: #dcdfe6"
              :class="{
                'btn-active':queryIndex != 3? dataItem.answer == '是' && dataItem.isSkip == 0 : dataItem.answer == '是',
              }"
              @click="clickAnswer('是')"
              round
              :disabled="dataItem.isSkip == 1"
              v-preventReClick="3000"
              >是</el-button
            >
            <el-button
              class="chooseItem"
              style="background: white; color: black; border-color: #dcdfe6"
              :class="{
                'btn-active':queryIndex != 3? dataItem.answer == '否' && dataItem.isSkip == 0 : dataItem.answer == '否',
              }"
              @click="clickAnswer('否')"
              round
              :disabled="dataItem.isSkip == 1"
              v-preventReClick="3000"
              >否</el-button
            >
            <!-- 专题没有不适用 -->
            <el-button
              class="chooseItem"
              v-if="queryIndex != 3"
              style="background: white; color: black; border-color: #dcdfe6"
              :class="{ 'btn-active': dataItem.isSkip == 1 }"
              @click="clickAnswer('不适用')"
              round
              v-preventReClick="3000"
              >不适用</el-button
            >
          </div>
          <div v-else style="margin-left: 20px">
            <div v-if="!showOneText">
              <p 
                style="padding-left: 40px; font-size: 17px; margin-top: 10px"
                v-for="(item, index) in dataItem.selectOption"
                v-show="item"
                :key="index"
              >
                <el-checkbox-group v-model="answer" v-if="dataItem.question.includes('多选')">
                  <el-checkbox :label="item.includes('.')?item.split('.')[0]:item.split('、')[0]" @change="radioInput" v-if="item">{{item}}</el-checkbox>
                </el-checkbox-group>
                <el-radio-group v-model="selectaAnswer" v-else>
                  <el-radio :label="item.includes('.')?item.split('.')[0]:item.split('、')[0]" v-if="item">{{item}}</el-radio>
                </el-radio-group>
              </p>
            </div>
            <p
              v-if="showOneText"
              style="padding-left: 40px; font-size: 17px; margin-top: 10px"
            >
              {{ dataItem.selectOption }}
            </p>
            <el-button
              class="chooseItem"
              v-if="queryIndex != 3"
              style="
                background: #e0e0e0;
                color: black;
                border-radius: 0px !important;
                margin-left: 40px !important;
                margin-top: 15px;
              "
              :class="{ 'skip-active': dataItem.isSkip == 1 }"
              @click="clickAnswer('不适用')"
              round
              v-preventReClick="3000"
              >不适用</el-button
            >
            <div slot="footer" class="select-bnt">
              <el-button
                class="chooseItem"
                @click="clickAnswer(selectaAnswer)"
                type="primary"
                :disabled="dataItem.isSkip == 1"
                v-preventReClick="3000"
                >确定</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-btn">
      <img
        style="width: 50px"
        src="../../assets/images/Examine_img/lean_img/back.png"
        @click="goOne(1)"
      />
      <img
        style="width: 50px"
        src="../../assets/images/Examine_img/lean_img/go.png"
        @click="goOne(2)"
      />
      <el-button
        type="danger"
        icon="el-icon-document-checked"
        @click="handleSubmitDialog"
        >交卷</el-button
      >
    </div>
    <!-- <el-dialog title="答案" :visible.sync="showDialog" width="25%">
      <div>
        <p>正确答案：{{ dataItem.quesAnswer }}</p>
        <p>{{ dataItem.interpretation }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDialog = false">确定</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      title="提示"
      :visible.sync="showSubmitDialog"
      width="30%"
      top="35vh"
      :before-close="handleSubmitClose"
    >
      <div>
        <span>是否确定交卷，交卷后不可更改！</span>
        <div style="margin-top: 30px; text-align: end">
          <el-button @click="showSubmitDialog = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, baseURL } from '../../api/http'
export default {
  data() {
    return {
      queryIndex: null,
      branch: '60',
      second: '00',
      time: null,
      questionList: [],
      dataItem: {},
      compName: '',
      deptName: '',
      record: 0,
      companyId: 0,
      deptId: 0,
      postId: 0,
      beforeUnloadTime: 0,
      selectaAnswer: '',
      answer:[],
      showOneText: false,
      showSubmitDialog: false,
    }
  },
  mounted() {
    //文件学习入口：1代表在外面选了公司部门岗位，2表示没选
    //专题学习入口：3代表专题学习
    this.queryIndex = Number(this.$route.query.queryIndex)
    if (this.queryIndex == 1) {
      this.postId = this.$route.query.postId
      this.companyId = this.$route.query.companyId
      this.deptId = this.$route.query.deptId
    } else if (this.queryIndex == 2) {
      const user = JSON.parse(sessionStorage.getItem('userInfo'))
      this.companyId = user.upCompanyId
      this.deptId = user.upDeptmentId
      this.postId = user.post
    }
    this.getExamId(JSON.parse(sessionStorage.getItem('userInfo')).id)
    // 切换标签时
    document.addEventListener('visibilitychange', this.handleVisibitState)
    // 刷新或关闭标签时
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
    window.addEventListener('unload', this.setData)
  },

  methods: {
    radioInput(){
      this.selectaAnswer = this.answer.join("");
    },
    handleVisibitState() {
      if (document.visibilityState == 'hidden') {
        clearInterval(this.time)
      } else if (document.visibilityState == 'visible') {
        this.timeFlow()
      }
    },
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime()
    },
    setData() {
      // 刷新比关闭时间短
      clearInterval(this.time)
      var timeDifference = new Date().getTime() - this.beforeUnloadTime //获取时间差
      if (timeDifference <= 5) {
        this.automaticSubmit() //关闭
      } else {
        this.setTime() //刷新
      }
    },
    automaticSubmit() {
      fetch(
        baseURL[process.env.NODE_ENV + ''] +
          (this.queryIndex == 3
            ? '/api/ThemeExamPlan/CompleteExam'
            : '/api/ExamPlan/CompleteExam') +
          '?ExamPlanId=' +
          this.questionList[0].examPlanId,
        {
          method: 'PUT',
          headers: {
            Authorization:
              'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
          },
          keepalive: true,
        }
      )
    },
    setTime() {
      var time = this.branch + ':' + this.second
      fetch(
        baseURL[process.env.NODE_ENV + ''] +
          (this.queryIndex == 3
            ? '/api/ThemeExamPlan/UpdateTime'
            : '/api/ExamPlan/UpdateTime') +
          '?ExamPlanId=' +
          this.questionList[0].examPlanId +
          '&ExamTime=' +
          time,
        {
          method: 'PUT',
          headers: {
            Authorization:
              'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
          },
          keepalive: true,
        }
      ).catch((e) => {
        console.log(e)
      })
    },
    // 倒计时显示
    timeFlow() {
      this.time = setInterval(() => {
        if (this.branch == '00' && this.second == '00') {
          clearInterval(this.time)

          return
        }
        if (this.second == '00') {
          this.second = '60'
          this.branch--
        }
        if (this.branch == '9') {
          this.branch = '09'
        }
        this.second--
        if (this.second < 10) {
          this.second = '0' + this.second
        }
      }, 1000)
    },

    // 获取题目
    async getCompyId() {
      await get('/api/Company/' + this.companyId).then((resp) => {
        if (resp.data != null && resp.data.length != 0)
          this.compName = resp.data.companyName
      })
      await get('/api/Department/GetById?Id=' + this.deptId).then((resp) => {
        if (resp.data != null && resp.data.length != 0)
          this.deptName = resp.data.deptmentName
      })
      this.getQuestionList()
    },
    getQuestionList() {
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      var data = {
        id: 0,
        examUserId: user.id,
        examUserName: user.userName,
        examScore: 0,
        status: 0,
        examTime: '60:00',
        remark: '',
        saveTime: new Date().toISOString(),
        saveUserId: user.id + '',
        saveUserNo: user.userNo,
        saveUserName: user.userName,
      }
      if (this.queryIndex != 3) {
        data.compId = this.companyId
        data.compName = this.compName
        data.deptId = this.deptId
        data.deptName = this.deptName
      }
      //新增考试记录
      var url = ''
      if (this.queryIndex == 3) {
        url = '/api/ThemeExamPlan?Theme=' + this.$route.query.theme
      } else {
        url = '/api/ExamPlan' + (this.postId ? '?postId=' + this.postId : '')
      }
      post(url, data).then((resp) => {
        if (resp.code == 200) {
          //  查询考试记录id
          this.getExamId(user.id)
        } else {
          if (resp.message == '考试题库为空！') {
            this.$confirm('考试题库为空，去导题')
              .then(() => {
                this.$router.push('./exam')
              })
              .catch(() => {
                this.$router.push('./exam')
              })
          }
        }
      })
    },
    getExamId(userId) {
      get(
        (this.queryIndex == 3
          ? '/api/ThemeExamPlan/GetByUserId'
          : '/api/ExamPlan/GetByUserId') +
          '?UserId=' +
          userId
      ).then((resp) => {
        if (resp.code == 200) {
          // 获取题目前还要判断是否存在未完成的考试
          var list = resp.data.filter((i) => {
            return i.status != 2
          })
          if (list.length == 0) {
            this.queryIndex == 3 ? this.getQuestionList() : this.getCompyId()
          } else {
            if (list[0].examTime != '') {
              this.branch = list[0].examTime.split(':')[0] + ''
              this.second = list[0].examTime.split(':')[1] + ''
            }
            if (this.queryIndex != 3) {
              this.companyId = list[0].compId
              this.deptId = list[0].deptId
              this.postId = list[0].postId
            }
            this.record = list[0].id
            //获取题目
            get(
              (this.queryIndex == 3
                ? '/api/ThemeExamPlanList/GetByExamPlanId'
                : '/api/ExamPlanList/GetByExamPlanId') +
                '?ExamPlanId=' +
                list[0].id
            ).then((resp) => {
              if (resp.code == 200) {
                this.questionList = resp.data
                this.dataItem = resp.data[0]
                if (this.dataItem.isSelect == 1) {
                  this.handleSelectOption()
                  this.selectaAnswer = this.dataItem.answer
                  this.answer = this.dataItem.answer.split('')
                }
                this.itemIndex = 0
                this.timeFlow()
              }
            })
          }
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    // 点击题目数字列表、前进后退按钮
    handleSelectOption() {
      this.showOneText = false
      let symbols = ["；", "。", "n/", "\n", ";","；\n","\r\n"];  
      if (typeof this.dataItem.selectOption === 'string') {  
        this.dataItem.selectOption = this.dataItem.selectOption.replace(/ /g, '').split(new RegExp(symbols.join("|")));  
      }
     
      // if (this.dataItem.selectOption.indexOf('n/') != -1) {
      //   this.dataItem.selectOption = this.dataItem.selectOption.split('n/')
      // } else if (this.dataItem.selectOption.indexOf('\n') != -1) {
      //   this.dataItem.selectOption = this.dataItem.selectOption.split('\n')
      // } else if (this.dataItem.selectOption.indexOf('。') != -1) {
      //   this.dataItem.selectOption = this.dataItem.selectOption.split('。')
      // } else if (this.dataItem.selectOption.indexOf('；') != -1) {
      //   this.dataItem.selectOption = this.dataItem.selectOption.split('；')
      // } else {
      //   this.showOneText = true
      // }
      // if (this.dataItem.selectOption instanceof Array && this.showOneText) {
      //   this.showOneText = false
      // }
    },
    clickItem(i) {
      if (this.branch == '00' && this.second == '00') {
        this.$message({
          type: 'warning',
          message: '您已无作答时间，请交卷',
        })
        return
      }
      this.itemIndex = i
      this.dataItem = this.questionList[i]
      if (this.dataItem.isSelect == 1) {
        this.selectaAnswer = this.dataItem.answer
        this.answer = this.dataItem.answer.split('')
        this.handleSelectOption()
      }
    },
    goOne(i) {
      if (this.branch == '00' && this.second == '00') {
        this.$message({
          type: 'warning',
          message: '您已无作答时间，请交卷',
        })
        return
      }
      if (this.itemIndex == 0 && i == 1) return
      if (this.itemIndex + 1 == this.questionList.length && i == 2) return
      this.itemIndex = i == 1 ? this.itemIndex - 1 : this.itemIndex + 1
      this.dataItem = this.questionList[this.itemIndex]
      if (this.dataItem.isSelect == 1) {
        this.selectaAnswer = this.dataItem.answer
        this.answer = this.dataItem.answer.split('')
        this.handleSelectOption()
      }
    },
    //跳过、答题、交卷
    clickAnswer(e) {
      if (this.branch == '00' && this.second == '00') {
        this.$message({
          type: 'warning',
          message: '您已无作答时间，请交卷',
        })
        return
      }
      if (!e) {
        this.$message({
          type: 'warning',
          message: '未选择答案',
        })
        return
      }
      var headerReq
      if (e == '不适用') {
        headerReq = put('/api/ExamPlanList/PutSkip?Id=' + this.dataItem.id)
      } else {
        var selectOptionList = ''
        if (this.dataItem.isSelect == 1 && !this.showOneText) {
          this.dataItem.selectOption.map((item) => {
            selectOptionList =
              selectOptionList + (selectOptionList ? 'n/' + item : item)
          })
        }
        var data = {
          id: this.dataItem.id,
          examPlanId: this.dataItem.examPlanId,
          questionId: this.dataItem.questionId,
          question: this.dataItem.question,
          answer: e,
          isSelect: this.dataItem.isSelect,
          quesAnswer: this.dataItem.quesAnswer,
          interpretation: this.dataItem.interpretation,
          selectOption:
            this.dataItem.isSelect == 1
              ? !this.showOneText
                ? selectOptionList
                : this.dataItem.selectOption
              : '',
          remark: this.dataItem.remark,
        }
        headerReq = put(
          this.queryIndex == 3 ? '/api/ThemeExamPlanList' : '/api/ExamPlanList',
          data
        )
      }
      headerReq
        .then((resp) => {
          if (resp.code == 200) {
            if (e != '不适用') {
              this.questionList[this.itemIndex].answer = e
            } else {
              this.questionList[this.itemIndex].isSkip = 1
            }
            if (this.itemIndex + 1 == this.questionList.length) return
            this.clickItem(this.itemIndex + 1)
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
    handleSubmitDialog() {
      this.showSubmitDialog = true
    },
    handleSubmitClose() {
      this.showSubmitDialog = false
    },
    async submit() {
      await put(
        (this.queryIndex == 3
          ? '/api/ThemeExamPlan/CompleteExam'
          : '/api/ExamPlan/CompleteExam') +
          '?ExamPlanId=' +
          this.questionList[0].examPlanId
      ).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: 'sucess',
            message: '提交成功',
          })
          this.getList()
        }
      })
    },
    getList() {
      get(
        (this.queryIndex == 3
          ? '/api/ThemeExamPlanList/GetByExamPlanId'
          : '/api/ExamPlanList/GetByExamPlanId') +
          '?ExamPlanId=' +
          this.record
      ).then((resp) => {
        var done = []
        var noDone = []
        var error = []
        var isSkip = []
        resp.data.map((i, index) => {
          // 0对，1错，2没做
          if (i.isSkip == 1) {
            isSkip.push(index + 1)
          } else {
            if (i.titleStatus == 0) {
              done.push(index + 1)
            }
            if (i.titleStatus == 1) {
              error.push({
                index: index + 1,
                id: i.id,
              })
            }
            if (i.titleStatus == 2) {
              noDone.push(index + 1)
            }
          }
        })
        var baseUrl =
          './score?id=' +
          this.record +
          '&done=' +
          JSON.stringify(done) +
          '&noDone=' +
          JSON.stringify(noDone) +
          '&error=' +
          JSON.stringify(error) +
          '&isSkip=' +
          JSON.stringify(isSkip) +
          '&queryIndex=' +
          this.queryIndex
        if (this.queryIndex == 1) {
          var url =
            baseUrl + '&companyId=' + this.companyId + '&deptId=' + this.deptId
          if (this.postId) {
            url = url + '&postId=' + this.postId
          }
          this.$router.push(url)
        } else {
          this.$router.push(
            baseUrl +
              (this.queryIndex == 3 ? '&theme=' + this.$route.query.theme : '')
          )
        }
      })
    },
    handleUpTime() {
      var time = this.branch + ':' + this.second
      put(
        (this.queryIndex == 3
          ? '/api/ThemeExamPlan/UpdateTime'
          : '/api/ExamPlan/UpdateTime') +
          '?ExamPlanId=' +
          this.questionList[0].examPlanId +
          '&ExamTime=' +
          time
      )
    },
  },
  beforeDestroy() {
    clearInterval(this.time)
    document.removeEventListener('visibilitychange', this.handleVisibitState)
    window.removeEventListener('beforeunload', this.setTime)
    if (this.questionList.length == 0) return
    this.handleUpTime()
  },
}
</script>

<style scoped>
@import '../../assets/style/Lean_CSS.css';
/* 淡入动画*/
.leanCss .el-dialog__wrapper {
  opacity: 0; /* 先将元素透明度设为0 */
  animation: fadeIn ease-in 1; /* 设置动画 */
  animation-fill-mode: forwards; /* 保持结束后状态 */
  animation-duration: 0.8s; /* 动画时长为0.8秒 */
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>

